export const subject = [
    {
        value: 0,
        label: '民法'
    }, {
        value: 1,
        label: '刑法'
    }, {
        value: 2,
        label: '行政法与行政诉讼法'
    }, {
        value: 3,
        label: '民讼法'
    }, {
        value: 4,
        label: '刑诉法'
    }, {
        value: 5,
        label: '融合学科'
    }, {
        value: 6,
        label: '理论法'
    }, {
        value: 7,
        label: '商经法'
    }]

export const idx2subject = {
    0: '民法',
    1: '刑法',
    2: '行政法',
    3: '民事诉讼法',
    4: '刑事诉讼法',
    5: '融合学科',
    6: '理论法',
    7: '商经法'
}
