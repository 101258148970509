<template>
    <div>
        <!-- 内容区 -->
        <div style="margin-bottom: 30px;">
            <el-alert
                style="margin-bottom: 20px;"
                v-if="$store.state.userInfo && !$store.state.userInfo.isRegister"
                title="当前题库为柏杜学员内部专属题库，题目数量更多，范围更广，质量更高！"
                type="error">
            </el-alert>
            <el-alert
                title="温馨提示：1.题库中的题目可以练习多次，但是不会进行智能批改！2.每道题目可能涉及多个大知识点，因此不同的大知识点下可能会有重叠的主观题题目。"
                type="warning">
            </el-alert>
        </div>

        <!-- 题库列表 -->
        <el-row :gutter="20">
            <el-col :span="8" v-for="(item, key) in dataList" :key="key">
                <div @click="showSubjective(key)">
                    <page-main class="paper-form" style="padding: 10px 20px 20px 20px; margin-bottom: 20px;">
                        <el-row type="flex" align="middle">
                            <el-col :span="18">
                                <div style="font-weight: bold; font-size: 18px;">
                                    {{ idx2subject[key] }}
                                </div>
                            </el-col>
                            <el-col :span="6" style="text-align: right">
                                <div style="cursor: pointer; color: #2196f3; font-size: 15px;">
                                    查看<i class="el-icon-s-promotion"></i>
                                </div>
                            </el-col>
                        </el-row>
                        <div style="margin-top: 30px; color: #909399; font-size: 15px;">
                            <i class="el-icon-menu">
                                题目数量：{{ item.count }}
                            </i>
                            <i class="el-icon-edit" style="margin-left: 40px;">
                                你已作答：{{ item.userCount }}
                            </i>
                        </div>
                    </page-main>
                </div>
            </el-col>
        </el-row>

        <!--章节弹窗-->
        <el-dialog
            :visible.sync="dialogVisible"
            custom-class="dialog"
            top="20vh"
            width="35%">
            <div slot="title">
                <h5 style="font-size: 18px; font-weight: bold">
                    {{ idx2subject[itemKey] }}
                </h5>
            </div>
            <GeminiScrollbar v-if="dataList[itemKey]"
                             style="margin-top: -20px; height: 500px; overflow-x: auto; overflow-y: scroll;">
                <div v-for="(chapterInfo, key) in dataList[itemKey].chapterInfoList"
                     :key="key"
                     @click="toSubjectList(chapterInfo.bdSubjectiveBigPoint.id)">
                    <el-row
                        style="background-color: #f5f5f5; margin-top: 20px; border-radius: 10px; padding: 20px; cursor: pointer;">
                        <el-col :span="20">
                            <h5 style="font-size: 15px; font-weight: bold; color: #333">
                                {{ chapterInfo.bdSubjectiveBigPoint.name }}
                            </h5>
                        </el-col>
                        <el-col :span="4" style="text-align: right; font-size: 15px;">
                            {{ '已答：' + chapterInfo.userCount + '/' + chapterInfo.count }}
                        </el-col>
                    </el-row>
                </div>
            </GeminiScrollbar>
        </el-dialog>
    </div>
</template>

<script>
import {idx2subject} from "../../../assets/json/subjectiveSubject";
import {Message} from "element-ui";

export default {
    name: "index",
    data() {
        return {
            idx2subject: idx2subject,
            dataList: [],
            chapterList: [],
            dialogVisible: false,
            itemKey: 0
        }
    },
    mounted() {
        // 获取数据
        this.$api.get('/subjective/getSubjectiveBank').then(res => {
            this.dataList = res.data
        })
    },
    methods: {
        // 去题目展示页面
        toSubjectList(id) {
            this.$router.push({name: 'subjectiveBankDetail', query: {bigPointId: id}})
        },
        // 查看章节
        showSubjective(key) {
            this.itemKey = key
            if (this.dataList[key].count === 0) {
                Message.warning('非常抱歉，当前科目下暂无题目~')
            } else {
                this.chapterList = this.dataList[key]
                this.dialogVisible = true
            }
        }
    }
}
</script>